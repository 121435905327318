import React from 'react';

import { useIntl } from 'react-intl';

import { Divider, GridLayout, useMessage, Text } from '@aprioritechnologies/core';
import { Card } from '@aprioritechnologies/labs';

import { StyledResourceList } from './resource-list.styles';

export type ResourceListId =
  'resource-list.title' |
  'resource-list.links.view-more' |
  'resource-list.links.open-support-ticket' |
  'resource-list.knowledgebase.title' |
  'resource-list.knowledgebase.description' |
  'resource-list.documentation.title' |
  'resource-list.documentation.description' |
  'resource-list.training.title' |
  'resource-list.training.description' |
  'resource-list.champions.title' |
  'resource-list.champions.description' |
  'resource-list.discussions.title' |
  'resource-list.discussions.description'|
  'resource-list.rdl.title' |
  'resource-list.rdl.description';

export enum Resource {
    KNOWLEDGEBASE = 'knowledgebase',
    DOCUMENTATION = 'documentation',
    TRAINING = 'training',
    CHAMPIONS = 'champions',
    DISCUSSIONS = 'discussions',
    RDL = 'rdl'
  }

const ResourceListIcon: Record<Resource, string> = {
  [Resource.KNOWLEDGEBASE]: `https://cdn.aws.apriori.com/icons/resources/knowledgebase.png`,
  [Resource.DOCUMENTATION]: 'https://cdn.aws.apriori.com/icons/resources/documentation.png',
  [Resource.TRAINING]: 'https://cdn.aws.apriori.com/icons/resources/training.png',
  [Resource.CHAMPIONS]: 'https://cdn.aws.apriori.com/icons/resources/champions-page.png',
  [Resource.DISCUSSIONS]: 'https://cdn.aws.apriori.com/icons/resources/discussions.png',
  [Resource.RDL]: 'https://cdn.aws.apriori.com/icons/resources/rdl-material-trends.png'
};

const ResourceListLinks: Record<Resource, [string, ResourceListId, string][]> = {
  [Resource.KNOWLEDGEBASE]: [
    [
      'resource-list-knowledgebase-view-more-link',
      'resource-list.links.view-more',
      'https://support.apriori.com/hc/en-us/categories/202585128-Knowledgebase'
    ],
    [
      'resource-list-knowledgebase-open-support-ticket-link',
      'resource-list.links.open-support-ticket',
      'https://support.apriori.com/hc/en-us/requests/new'
    ]
  ],
  [Resource.DOCUMENTATION]: [
    [
      'resource-list-documentation-view-more-link',
      'resource-list.links.view-more',
      'https://docs.apriori.com'
    ]
  ],
  [Resource.TRAINING]: [
    [
      'resource-list-training-view-more-link',
      'resource-list.links.view-more',
      'https://www.apriori.com/training/'
    ]
  ],
  [Resource.CHAMPIONS]: [
    [
      'resource-list-champions-view-more-link',
      'resource-list.links.view-more',
      'https://support.apriori.com/hc/en-us/categories/360000131488-Champions-Page'
    ]
  ],
  [Resource.DISCUSSIONS]: [
    [
      'resource-list-discussions-view-more-link',
      'resource-list.links.view-more',
      'https://support.apriori.com/hc/en-us/community/topics'
    ]
  ],
  [Resource.RDL]: [
    [
      'resource-list-rdl-view-more-link',
      'resource-list.links.view-more',
      'https://support.apriori.com/hc/en-us/p/data-trends'
    ]
  ]
};

export const ResourceList = () => {

  type T = ResourceListId;
  const resourceListTitle = useMessage<T>('resource-list.title');
  const { messages } = useIntl();

  const renderLink = (
    link: [string, ResourceListId, string]
  ) => {

    const [id, messageId, url] = link;
    const title = messages[messageId] as string;

    return (
      <a
        id={id}
        key={id}
        data-link={id}
        className='resource-list-section-link'
        target='_blank'
        rel='noreferrer noopener'
        href={url}
      >
        {title}
      </a>
    );
  };

  const renderResource = (resource: Resource) => {
    const titleKey = `resource-list.${resource}.title`;
    const descriptionKey = `resource-list.${resource}.description`;
    const title = messages[titleKey] as string;
    const description = messages[descriptionKey] as string;
    const icon = ResourceListIcon[resource];
    const links = ResourceListLinks[resource];

    return (
      <GridLayout
        key={resource}
        className='resource-list-section'
        gridTemplateColumns='auto 1fr'
        alignItems='start'
        data-name={title}
      >
        <img className='resource-list-section-icon' alt={title} src={icon} />
        <div className='resource-list-section-metadata'>
          <h3 className='resource-list-section-title'>{title}</h3>
          <div className='resource-list-section-description'>{description}</div>
          <div className='resource-list-section-links'>
            {links.map(renderLink)}
          </div>
        </div>
      </GridLayout>
    );
  };

  const renderTitle = () => (
    <GridLayout className='resource-list-title' alignItems='center' gridTemplateColumns='1fr'>
      <Text.H2>{resourceListTitle}</Text.H2>
    </GridLayout>
  );

  const renderSections = () => {
    return (
      <GridLayout className='resource-list-links' gridTemplateColumns='1fr 1fr' gap='none'>
        {Object.values(Resource).map((r) => renderResource(r))}
        {Object.keys(Resource).length / 2 !== 0}
      </GridLayout>
    );
  };

  return (
    <StyledResourceList>
      <Card className='resource-list'>
        {renderTitle()}
        <Divider />
        {renderSections()}
      </Card>
    </StyledResourceList>
  );
};
