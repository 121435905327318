/* eslint-disable max-len */
import { CoreEn } from '@aprioritechnologies/core';
import { labsEnMessages } from '@aprioritechnologies/labs';

import { AchLanguage } from './message.type';

const AchEn: AchLanguage = Object.freeze({
  ...CoreEn,
  ...labsEnMessages,

  'ach-header.home-title' : 'Home',
  'ach-header.menu-item.switch-customer-title' : 'Switch Customer',
  'ach-header.menu-item.switch-deployment-title' : 'Switch Deployment',
  'ach-header.menu-item.user-management' : 'User Management',
  'ach-header.menu-item.view-user-activity' : 'View User Activity',
  'ach-header.help-menu-item.user-events-guide' : 'Dashboard User Guide',

  'application-card.link.title.docs': 'Docs',
  'application-card.link.title.training': 'Training',
  'application-card.link.title.support': 'Support',

  'notification-list.column.information': 'Information',
  'notification-list.column.type': 'Type',
  'notification-list.column.priority': 'Priority',
  'notification-list.filter.priority.all': 'All',
  'notification-list.filter.priority.urgent': 'Urgent',
  'notification-list.filter.priority.title': 'Priority',
  'notification-list.filter.sort.newest-first': 'Newest First',
  'notification-list.filter.sort.oldest-first': 'Oldest First',
  'notification-list.filter.sort.title': 'Sort',
  'notification-list.filter.type.all': 'All',
  'notification-list.filter.type.title': 'Type',
  'notification-list.no-notifications-available': 'No Notifications Available',
  'notification-list.title': 'Notifications',

  'resource-list.title': 'Resources',
  'resource-list.links.view-more': 'View More',
  'resource-list.links.open-support-ticket': 'Open support ticket',
  'resource-list.knowledgebase.title': 'Knowledgebase',
  'resource-list.knowledgebase.description': 'Articles about aPriori product usage and management, supported processes, and troubleshooting.',
  'resource-list.documentation.title': 'Documentation',
  'resource-list.documentation.description': 'Documentation for current and past versions of aPriori applications in English and other languages.',
  'resource-list.training.title': 'Training',
  'resource-list.training.description': 'aPriori Training resources. Access aPriori Academy and Register for LIVE courses.',
  'resource-list.champions.title': 'Champions Page',
  'resource-list.champions.description': 'Strategies, playbooks, and case studies to help onboard customers, implement aPriori tools, and maximize the value they get.',
  'resource-list.discussions.title': 'Discussions',
  'resource-list.discussions.description': 'Forums for discussing aPriori related topics',
  'resource-list.rdl.title': 'RDL Data Trends',
  'resource-list.rdl.description': 'Review current RDL data trended against previous releases',

  'switch-deployment-dialog.buttons.cancel': 'Cancel',
  'switch-deployment-dialog.buttons.switch': 'Switch',
  'switch-deployment-dialog.deployment.preview': 'Preview',
  'switch-deployment-dialog.deployment.production': 'Production',
  'switch-deployment-dialog.deployment.sandbox': 'Sandbox',
  'switch-deployment-dialog.instructions': 'Select the deployment you would like to switch to:',
  'switch-deployment-dialog.title': 'Switch Deployment',

  'user-activity-view.error-display.home-button': 'Home',
  'user-activity-view.error-display.retry-button': 'Retry',
  'user-activity-view.error-display.message': 'There was an issue loading your data. If the issue persists, please contact',
  'user-activity-view.error-display.support-link-text': 'aPriori support',
  'user-activity-view.error-display.title': 'Something Went Wrong',

  'user-management.actions.delete.confirm': 'Are you sure you want to remove {givenName} {familyName}? This action cannot be undone.',
  'user-management.actions.delete.confirm.yes': 'Yes',
  'user-management.actions.delete.confirm.no': 'No',
  'user-management.button.add-user': 'Add User',
  'user-management.columns.username': 'Username',
  'user-management.columns.email': 'Email',
  'user-management.columns.family-name': 'Family Name',
  'user-management.columns.given-name': 'Given Name',
  'user-management.columns.status': 'Status',
  'user-management.columns.assigned-role': 'User Role',
  'user-management.columns.job-title': 'Job Title',
  'user-management.columns.created-at': 'Created At',
  'user-management.columns.updated-at': 'Updated At',
  'user-management.columns.actions': 'Actions',
  'user-management.columns.actions.edit': 'Edit',
  'user-management.columns.actions.delete': 'Delete',
  'user-management.columns.actions.read-only': 'User is read only',
  'user-management.status.active': 'Active',
  'user-management.status.inactive': 'Inactive',
  'user-management.title': 'User Management',

  'user-profile.form.contact-administrator': 'Please contact your administrator to fill username and email!',
  'user-profile.form.incomplete-profile': 'Your aPriori user profile is incomplete, this may be due to a role change. Your current roles are:',
  'user-profile.form.invalid-email': 'Must be a valid email.',
  'user-profile.form.invalid-phone-country-code': 'Phone country code is not valid.',
  'user-profile.form.invalid-phone-number': 'Phone number is not valid.',
  'user-profile.form.no-roles': 'Your profile does not have any roles set',
  'user-profile.form.select-value': 'Select...',
  'user-profile.form.required': 'Required',
  'user-profile.form.length-max-message': 'Cannot exceed {value} characters.',
  'user-profile.form.username': 'Username',
  'user-profile.form.email': 'Email',
  'user-profile.form.email-hint-text': 'Username will be created from email address',
  'user-profile.form.givenName': 'Given Name',
  'user-profile.form.familyName': 'Family Name',
  'user-profile.form.prefix': 'Name prefix',
  'user-profile.form.suffix': 'Name suffix',
  'user-profile.form.job-title': 'Job title',
  'user-profile.form.department': 'Department',
  'user-profile.form.townCity': 'Town or City',
  'user-profile.form.county': 'County',
  'user-profile.form.country': 'Country',
  'user-profile.form.timezone': 'Time Zone',
  'user-profile.form.title.edit': 'Edit User Details',
  'user-profile.form.title.add': 'Add User Details',
  'user-profile.form.office-phone-country-code': 'Office Phone Country Code',
  'user-profile.form.office-phone-number': 'Office Phone Number',
  'user-profile.button.edit': 'Edit',
  'user-profile.button.save': 'Save',
  'user-profile.button.finish': 'Finish',
  'user-profile.button.next': 'Next',
  'user-profile.button.previous': 'Previous',
  'user-profile.button.cancel': 'Cancel',
  'user-profile.button.confirm': 'Confirm',
  'user-profile.cancel.title': 'Are you sure?',
  'user-profile.cancel.message': 'Confirming this action will terminate your session.',
  'user-profile.role.label': 'User Role',
  'user-profile.enablements.additional-properties.label': 'Additional Properties',
  'user-profile.enablements.additional-properties.connect.admin': 'aP Connect Admin',
  'user-profile.enablements.additional-properties.export.admin': 'aP Export Admin',
  'user-profile.enablements.additional-properties.user.admin': 'aP User Admin',
  'user-profile.enablements.additional-properties.high.memory': 'aP High Memory',
  'user-profile.enablements.additional-properties.disabled.tooltip': '{property} can only be used with {customerAssignedRoles} role(s).',
  'user-profile.enablements.additional-properties.disabled.coming-soon': 'Functionality will be available soon.',
  'user-profile.enablements.additional-deployment.disabled.tooltip': 'No {property} deployment is currently available.',
  'user-profile.enablements.deployments.label': 'Deployments',
  'user-profile.enablements.deployments.preview': 'aP Preview',
  'user-profile.enablements.deployments.sandbox': 'aP Development Sandbox',
  'user-profile.enablements.disabled': 'This user is not able to have their enablements edited.\n\nService accounts are readonly, and administrators are not allowed to update their own enablements.\n\n If you are an administrator and you need your enablements updated, please ask another user administrator to make this change.',
  'user-profile.select.placeholder': 'Make Selection'
});

export default AchEn;
