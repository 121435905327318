import React, { useLayoutEffect, useMemo, useRef, useState } from 'react';

import { IconLookup } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useIntl } from 'react-intl';

import { IconType, LineItem, Tooltip } from '@aprioritechnologies/core';
import { renderDateTimeCell, renderOverflowCell, renderOverflowHeader, renderTruthyFalsyCell } from '@aprioritechnologies/data-source-react';
import { MenuButton, MenuItem, useMenuCloseHandle } from '@aprioritechnologies/labs';

import { AchLanguage } from '../../../intl/message.type';
import { User } from '../../../model/user';

export interface UserManagementOperations {
  edit?(item: User): void;
  remove?(item: User): void;
}

const createUsernameColumn = (dict: AchLanguage) => ({
  accessor: 'username',
  Header: renderOverflowHeader.bind(null, dict['user-management.columns.username']),
  Cell: renderOverflowCell
});

const createEmailColumn = (dict: AchLanguage) => ({
  accessor: 'email',
  Header: renderOverflowHeader.bind(null, dict['user-management.columns.email']),
  Cell: renderOverflowCell
});

const createCustomerAssignedRoleColumn = (dict: AchLanguage) => ({
  accessor: 'enablements.customerAssignedRole',
  Header: renderOverflowHeader.bind(null, dict['user-management.columns.assigned-role']),
  Cell: renderOverflowCell
});

const createJobTitleColumn = (dict: AchLanguage) => ({
  accessor: 'userProfile.jobTitle',
  Header: renderOverflowHeader.bind(null, dict['user-management.columns.job-title']),
  Cell: renderOverflowCell
});

const createFamilyNameColumn = (dict: AchLanguage) => ({
  accessor: 'userProfile.familyName',
  Header: renderOverflowHeader.bind(null, dict['user-management.columns.family-name']),
  Cell: renderOverflowCell
});

const createGivenNameColumn = (dict: AchLanguage) => ({
  accessor: 'userProfile.givenName',
  Header: renderOverflowHeader.bind(null, dict['user-management.columns.given-name']),
  Cell: renderOverflowCell
});

const createStatusColumn = (dict: AchLanguage) => ({
  accessor: 'active',
  Header: renderOverflowHeader.bind(null, dict['user-management.columns.status']),
  Cell: renderTruthyFalsyCell.bind(
    null,
    dict['user-management.status.active'],
    dict['user-management.status.inactive'])
});

const createCreatedAtColumn = (dict: AchLanguage) => ({
  accessor: 'createdAt',
  Header: renderOverflowHeader.bind(null, dict['user-management.columns.created-at']),
  Cell: renderDateTimeCell
});

const createUpdatedAtColumn = (dict: AchLanguage) => ({
  accessor: 'updatedAt',
  Header: renderOverflowHeader.bind(null, dict['user-management.columns.updated-at']),
  Cell: renderDateTimeCell
});

const createActionColumn = (dict: AchLanguage, operations: UserManagementOperations) => ({
  accessor: 'actions',
  Header: renderOverflowHeader.bind(null, dict['user-management.columns.actions']),
  disableSortBy: true,
  Cell: (cell: any) => {
    const { row } = cell;
    const { original: user } = row;
    const { handle, closeMenu } = useMenuCloseHandle();
    const [size, setSize] = useState({ width: 0, height: 0 });
    const [visible, setVisible] = useState(true);
    const ref = useRef<HTMLDivElement>(null);

    useLayoutEffect(() => {
      if (ref.current) {

        /* Get size of button to set it for tooltip */
        const child = ref.current.children[0];
        setSize({
          width: child instanceof HTMLElement ? child.offsetWidth : 0,
          height: ref.current.offsetHeight
        });

        /* Hide button for system accounts */
        if (!user.canEditProfile) {
          setVisible(false);
        }
      }
    }, []);

    const renderMenuItem = (
      name: string,
      handle: (() => void) | undefined,
      id: string,
      icon: IconLookup,
      label: string) => {
      if (handle == null) {
        return null;
      }

      const _handle = () => {
        closeMenu();
        handle();
      };

      return (
        <MenuItem id={id} className='action-menu-item' data-name={name} onClick={_handle}>
          <LineItem
            prefix={<FontAwesomeIcon icon={icon} width='1em'/>}
            body={label}
          />
        </MenuItem>
      );
    };

    return (
      <div>
        {visible && <div ref={ref}>
          <MenuButton label={<FontAwesomeIcon icon={IconType.actionList}/>} closeHandle={handle}>
            {
              renderMenuItem(
                'edit',
                operations.edit?.bind(operations, user),
                'user-management-edit-menu-button',
                IconType.edit,
                dict['user-management.columns.actions.edit'])
            }
            {
              renderMenuItem(
                'delete',
                user.canDelete ? operations.remove?.bind(operations, user) : undefined,
                'user-management-delete-menu-button',
                IconType.trash,
                dict['user-management.columns.actions.delete'])
            }
          </MenuButton>
        </div>
        }
        {!user.canEditProfile &&
        <Tooltip placement='bottom' title={dict['user-management.columns.actions.read-only']}>
          <div style={{ height: size.height, width: size.width }}/>
        </Tooltip>
        }
      </div>
    );
  }
});

const createUserManagementListColumns = (
  dict: AchLanguage,
  operations: UserManagementOperations
) => [
  createUsernameColumn(dict),
  createEmailColumn(dict),
  createFamilyNameColumn(dict),
  createGivenNameColumn(dict),
  createCustomerAssignedRoleColumn(dict),
  createJobTitleColumn(dict),
  createStatusColumn(dict),
  createCreatedAtColumn(dict),
  createUpdatedAtColumn(dict),
  createActionColumn(dict, operations)
];

export const useUserManagementListColumns = (operations: UserManagementOperations) => {
  const intl = useIntl();
  const messages = intl.messages as AchLanguage;
  return useMemo(
    () => createUserManagementListColumns(messages, operations),
    [operations]
  );
};
